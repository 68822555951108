<template>
  <div>
    <navbar />
    <image-banner
      title="CAREERS"
      subtitle="Get to know what inspires us, our core values and our Team"
      img="careerheader.png"
      date=""
    ></image-banner>
    <div class="p-4 md:p-16">
      <div class="grid md:grid-cols-3 mt-16 mb-8 gap-16">
        <div class="col-span-2 flex flex-col justify-center md:py-4">
          <h1 class="mb-3 font-bold">Join Our Amazing Team</h1>
          <p class="text-sm">
            Are you looking to start a career at Teklabspace? If you have a
            passion for security, this just might be the place for you. If you
            are interested in one of our available positions, please apply
            through the listings below. By sending a mail to
            <span class="text-blue-500"> info@teklabspace.com</span> with the
            job title as the subject and attache your Resume/CV
          </p>
        </div>
        <div class="col-span-2 md:col-span-1">
          <img src="../assets/img/career.png" alt="" />
        </div>
      </div>

      <div class="flex items-center justify-center">
        <span class="short__border"></span>
      </div>
      <h1 class="mt-3 text-center mb-10 font-bold">BENEFITS</h1>
      <div class="flex justify-center">
        <div class="w-full md:w-2/3">
          <div class="grid md:grid-cols-2 gap-10">
            <div class="benefit rounded-md p-5">
              <span
                class="mb-4 inline-block shadow-sm rounded-full w-14 h-14 flex items-center justify-center"
              >
                <i class="fas fa-check-circle color-pink text-4xl"></i>
              </span>
              <h3 class="font-bold text-xl mb-3">Work with the best</h3>
              <p class="text-xs" style="line-height: 1.6">
                You will have the opportunity to help shape the future of the
                platform, bringing your own flare and skill set to our
                world-class team.
              </p>
            </div>
            <div class="benefit rounded-md p-5">
              <span
                class="mb-4 inline-block shadow-sm rounded-full w-14 h-14 flex items-center justify-center"
              >
                <i class="fas fa-arrow-right color-pink text-4xl"></i>
              </span>
              <h3 class="font-bold text-xl mb-3">Excellent benefits</h3>
              <p class="text-xs" style="line-height: 1.6">
                Benefits of being part of the team will include attendance of
                conferences, full access to the Teklabspace offerings and
                flexibility in working hours.
              </p>
            </div>
            <div class="benefit rounded-md p-5">
              <span
                class="mb-4 inline-block shadow-sm rounded-full w-14 h-14 flex items-center justify-center"
              >
                <i class="fas fa-briefcase color-pink text-4xl"></i>
              </span>
              <h3 class="font-bold text-xl mb-3">Experience over education</h3>
              <p class="text-xs" style="line-height: 1.6">
                We understand that not everyone given the opportunity of getting
                a university degree. We are seeking applicants who are always
                looking to better themselves.
              </p>
            </div>
            <div class="benefit rounded-md p-5">
              <span
                class="mb-4 inline-block shadow-sm rounded-full w-14 h-14 flex items-center justify-center"
              >
                <i class="fas fa-laptop color-pink text-4xl"></i>
              </span>
              <h3 class="font-bold text-xl mb-3">Latest technology</h3>
              <p class="text-xs" style="line-height: 1.6">
                Work with the latest software. We make sure you have the tools
                and infrastructure you need to get the job done right.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-center mt-14">
        <span class="short__border"></span>
      </div>
      <h1 class="mt-3 text-center mb-14 font-bold">CURRENT OPENINGS</h1>

      <div class="md:px-10">
        <div
          class="bg-gray-200 mb-3 rounded p-3 flex justify-between items-center"
        >
          <div>
            <p class="font-bold text-lg mb-0">Content Engineer</p>
            <span class="text-sm"
              >LOCATION: West Africa, East Africa, North Africa</span
            >
          </div>
          <span>
            <i class="fas fa-plus cursor-pointer"></i>
          </span>
        </div>
        <div
          class="bg-gray-200 mb-3 rounded p-3 flex justify-between items-center"
        >
          <div>
            <p class="font-bold text-lg mb-0">Sales Executive Manager</p>
            <span class="text-sm"
              >LOCATION: West Africa, East Africa, North Africa, South
              Africa</span
            >
          </div>
          <span>
            <i class="fas fa-plus cursor-pointer"></i>
          </span>
        </div>
        <div
          class="bg-gray-200 mb-3 rounded p-3 flex justify-between items-center"
        >
          <div>
            <p class="font-bold text-lg mb-0">Frontend Developer</p>
            <span class="text-sm">LOCATION: Remote</span>
          </div>
          <span>
            <i class="fas fa-plus cursor-pointer"></i>
          </span>
        </div>
        <div
          class="bg-gray-200 mb-3 rounded p-3 flex justify-between items-center"
        >
          <div>
            <p class="font-bold text-lg mb-0">PHP, C# Developer</p>
            <span class="text-sm">LOCATION: Remote</span>
          </div>
          <span>
            <i class="fas fa-plus cursor-pointer"></i>
          </span>
        </div>
        <div
          class="bg-gray-200 mb-3 rounded p-3 flex justify-between items-center"
        >
          <div>
            <p class="font-bold text-lg mb-0">Product Manager</p>
            <span class="text-sm"
              >LOCATION: West Africa, East Africa, North Africa</span
            >
          </div>
          <span>
            <i class="fas fa-plus cursor-pointer"></i>
          </span>
        </div>
        <div
          class="bg-gray-200 mb-3 rounded p-3 flex justify-between items-center"
        >
          <div>
            <p class="font-bold text-lg mb-0">Graphics Designer</p>
            <span class="text-sm">LOCATION: Remote</span>
          </div>
          <span>
            <i class="fas fa-plus cursor-pointer"></i>
          </span>
        </div>
        <div
          class="bg-gray-200 mb-3 rounded p-3 flex justify-between items-center"
        >
          <div>
            <p class="font-bold text-lg mb-0">Training Content Developer</p>
            <span class="text-sm"
              >LOCATION: West Africa, East Africa, North Africa</span
            >
          </div>
          <span>
            <i class="fas fa-plus cursor-pointer"></i>
          </span>
        </div>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<script>
import navbar from "@/components/extra/navbar";
import ImageBanner from "@/components/extra/ImageBanner";
import MainFooter from "@/components/extra/MainFooter";

export default {
  name: "Careers",
  components: {
    navbar,
    ImageBanner,
    MainFooter,
  },
};
</script>

<style>
.short__border {
  content: "";
  position: relative;
  border: 3px solid #da04f2;
  width: 5%;
}

.benefit {
  border: 3px solid black !important;
}
</style>